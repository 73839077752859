document.addEventListener("DOMContentLoaded", function () {

    const slider = document.querySelector(".valoraciones__vistas--contenido");
    const valoraciones = document.querySelectorAll(".valoraciones__vistas--valoracion");
    const prevBtn = document.querySelector(".prev-btn");
    const nextBtn = document.querySelector(".next-btn");
    let currentIndex = 0;

    prevBtn.addEventListener("click", function () {
        
        if (currentIndex > 0) {
            currentIndex--;
            actualizarSlider();
        }
    });

    nextBtn.addEventListener("click", function () {
        if (currentIndex < valoraciones.length - 1) {
            currentIndex++;
            actualizarSlider();
        }
    });

    function actualizarSlider() {
        
        const medidaContenedor = valoraciones[0].offsetWidth;
        slider.style.transition = "transform 0.5s ease";
        slider.style.transform = `translateX(-${ currentIndex * medidaContenedor}px)`;
    }
     // Listener para el evento de redimensionar la ventana
    window.addEventListener("resize", function () {
        // Restablecer currentIndex a cero al cambiar el tamaño de la ventana
    
        actualizarSlider();
    });
    
});
